<template>
  <div>
    <embed-header />
    <main>
      <nuxt />
    </main>
    <Footer />
  </div>
</template>

<script>
import AuthCheck from '~/plugins/mixins/auth-check'
export default {
  mixins: [
    AuthCheck
  ],
  mounted() {
    setInterval(function() {
      window.top.postMessage({documentHeight: document.body.scrollHeight}, "*");
    }, 500);
  },
  head () {
    return {
      titleTemplate: '%s - ' + process.env.TITLE,
      meta: [
        { hid: 'title', name: 'title', content: process.env.META_TITLE },
        { hid: 'description', name: 'description', content: process.env.META_DESCRIPTION },
        { hid: 'theme-color', name: 'theme-color', content: '#72ba00'},
        { hid: 'site_name', name: 'site_name', content: process.env.TITLE},
        { hid: 'author', name: 'author', content: process.env.AUTHOR},
        { hid: 'theme', name: 'theme', content: '#72ba00'},
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: process.env.TITLE},
        { hid: 'og:title', property: 'og:title', content: process.env.TITLE},
        { hid: 'og:site_name', property: 'og:site_name', content: process.env.TITLE},
        { hid: 'og:description', property: 'og:description', content: process.env.META_DESCRIPTION},
        { hid: 'og:type', property: 'og:type', content: 'website'},
        { hid: 'fb:app_id', property: 'fb:app_id', content: process.env.FB_APPID}
      ],
      bodyAttrs:{
        class:'widget-layout'
      }
    }
  }
}
</script>
