export default ({ app, redirect, route }) => {
  var storeCoords = app.store.getters['location/coords']
  if(storeCoords?.latitude !== undefined && storeCoords?.longitude !== undefined) {
    return;
  }
  let storeLocation = (function(coords, code, message){
    app.store.dispatch('location/setGeoLocation', {
      coords: coords,
      code: code,
      message: message
    })
  });
  if (typeof window !== 'undefined' && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
        function (position) {
          storeLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              altitude: position.coords.altitude,
              accuracy: position.coords.accuracy
          }, 200, 'OK')
        },
        function (error) {
          storeLocation(undefined, error.code, error.message)
        }, {
          enableHighAccuracy: true,
          timeout: 5000
        }
    )
  } else {
    let msg = 'Geolocation is not supported by this browser.';
    storeLocation(undefined, 1, msg)
    new Error(msg)
  }
}
